<template>
  <div class="container">
    <Breadcrumb />
    <div class="row mb-3">
      <BlogList />
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card widget widget-stats">
          <div class="card-body">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-success">
                <i class="bi bi-people-fill"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "Home.UserCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>
                <div
                  class="spinner-grow text-success"
                  role="status"
                  v-if="total.userCountRequestStarting"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span
                  class="widget-stats-amount text-success fw-bolder fs-2"
                  v-else
                  >{{
                    String.numberWithCommas(
                      total.userCount,
                      this.$store.state.activeLang
                    )
                  }}</span
                >
              </div>
            </div>
            <div class="widget-stats-progress">
              <div class="progress m-b-sm">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  :style="`width: ${total.userCount}%`"
                  :aria-valuenow="total.userCount"
                  aria-valuemin="0"
                  :aria-valuemax="total.userCount"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card widget widget-stats">
          <div class="card-body">
            <div class="widget-stats-container d-flex">
              <div class="widget-stats-icon widget-stats-icon-primary">
                <i class="bi bi-boxes"></i>
              </div>
              <div class="widget-stats-content flex-fill">
                <span class="widget-stats-title">{{
                  $t(
                    "Home.CustomObjectCount",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>
                <div
                  class="spinner-grow text-primary"
                  role="status"
                  v-if="total.customObjectRequestStarting"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span
                  class="widget-stats-amount text-primary fw-bolder fs-2"
                  v-else
                  >{{
                    String.numberWithCommas(
                      total.customObjectCount,
                      this.$store.state.activeLang
                    )
                  }}</span
                >
              </div>
            </div>
            <div class="widget-stats-progress">
              <div class="progress m-b-sm">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :style="`width: ${total.customObjectCount}%`"
                  :aria-valuenow="total.customObjectCount"
                  aria-valuemin="0"
                  :aria-valuemax="total.customObjectCount"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlogList from "@/components/page-partials/home/BlogList.vue";
export default {
  components: {
    BlogList,
  },
  data() {
    return {
      total: {
        customObjectRequestStarting: true,
        customObjectCount: 0,
        userCountRequestStarting: true,
        userCount: 0,
      },
    };
  },
  methods: {
    getTotals() {
      this.$prodGatewayAxios
        .get("/Lcdp-CustomObjectList")
        .then((response) => {
          this.total.customObjectRequestStarting = false;
          this.total.customObjectCount = response.data.itemCount;
        })
        .catch(function (error) {
          //alert(error);
        });

      this.$prodGatewayAxios
        .get("/Lcdp-ListUsers?pageSize=0&page=1")
        .then((response) => {
          this.total.userCountRequestStarting = false;
          this.total.userCount = response.data.itemCount;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getTotals();
  },
};
</script>
