<template>
  <Carousel
    :pauseAutoplayOnHover="true"
    :autoplay="3000"
    :wrap-around="true"
    :transition="200"
    :breakpoints="breakpoints"
  >
    <Slide v-for="blog in slideItems" :key="blog.id">
      <div class="carousel__item">
        <div class="carousel__item-img" :style="blog.style">
          <img
            :src="getImage(blog.imagePath)"
            :title="blog.text"
            :alt="blog.text"
          />
        </div>
        <div class="carousel__item-desc">
          {{ blog.text }}
        </div>
        <div class="carousel__item-btns">
          <a
            :href="blog.url"
            target="_blank"
            class="btn btn-success"
            :title="
              this.$t(
                'Components.BlogList.ReadDetail',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          >
            {{
              $t(
                "Components.BlogList.ReadDetail",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <i class="bi bi-arrow-up-right-square m-0"></i>
          </a>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Autoplay",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      slideItems: [
        {
          id: 1,
          text: this.$t(
            "Components.BlogList.WhatIsSetXRM",
            {},
            { locale: this.$store.state.activeLang }
          ),
          imagePath: require("@/assets/images/blog/setxrm-nedir-setxrm-avantajlari-nelerdir.png"),
          style: "background-color:#b2e5e7",
          url: "https://www.setxrm.com/blog/neden-setxrm/",
        },
        {
          id: 2,
          text: this.$t(
            "Components.BlogList.SetXRMWorkflowManagement",
            {},
            { locale: this.$store.state.activeLang }
          ),
          imagePath: require("@/assets/images/blog/setxrm-is-akis-yonetimi.png"),
          style: "background-color:#ebdfeb",
          url: "https://www.setxrm.com/blog/setxrm-is-akis-yonetimi/",
        },
        {
          id: 3,
          text: this.$t(
            "Components.BlogList.WhatIsDashboardReporting",
            {},
            { locale: this.$store.state.activeLang }
          ),
          imagePath: require("@/assets/images/blog/setxrm-raporlama.png"),
          style: "background-color:#d0f3e8",
          url: "https://www.setxrm.com/blog/dashboard-raporlama-nedir/",
        },
        {
          id: 4,
          text: this.$t(
            "Components.BlogList.AllOurArticles",
            {},
            { locale: this.$store.state.activeLang }
          ),
          imagePath: require("@/assets/images/blog/setxrm-read-all-blogs.png"),
          style: "background-color:#e7e7e7",
          url: "https://www.setxrm.com/blog/",
        },
      ],
      breakpoints: {
        300: {
          itemsToShow: 1,
          snapAlign: "end",
        },
        700: {
          itemsToShow: 2,
          snapAlign: "end",
        },
        1024: {
          itemsToShow: 2,
          snapAlign: "end",
        },
        1025: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    getImage(imagePath) {
      return imagePath;
    },
  },
});
</script>

<style></style>
